import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

const cache = new Map(); // Cache to store fetched pages

const Home = () => {
  const { "*": slug } = useParams(); // Capture full dynamic slug
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageContent, setPageContent] = useState(null); // Store page content
  const observerRef = useRef(null);
  const startTimeRef = useRef(null); // Store start time

  useEffect(() => {
    startTimeRef.current = performance.now();
    fetchPageContent(slug || "");
    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [slug]);

  const fetchPageContent = async (pageSlug) => {
    setLoading(true);
    setError(null);

    if (cache.has(pageSlug)) {
      console.log(`[CACHE HIT] Page: ${pageSlug}`);
      setPageContent(cache.get(pageSlug)); // Store cached content
      logPageLoadTime();
      return;
    }

    try {
      const url = `https://ifuturz.com/wp/${pageSlug || ""}`;
      console.log("Fetching page from URL:", url);

      const response = await fetch(url, { cache: "force-cache" });

      if (!response.ok) throw new Error(`Failed to fetch page: ${response.status}`);

      let htmlText = await response.text();
      htmlText = modifyNavMenuLinks(htmlText);
       
      cache.set(pageSlug, htmlText);
      setPageContent(htmlText); // Store content in state
      executeScripts(htmlText) 
      logPageLoadTime();
      
    } catch (err) {
      setError(`Failed to load page content. Error: ${err.message}`);
      console.error(err);
      setLoading(false);
    }
  };

  const logPageLoadTime = () => {
    const endTime = performance.now();
    const loadTime = ((endTime - startTimeRef.current) / 1000).toFixed(2);
    console.log(`%c[PAGE LOAD TIME] ${loadTime} sec`, "color: blue; font-weight: bold;");
    setLoading(false);
  };

  const updateDocumentContent = () => {
    if (pageContent) {
      setTimeout(() => {
        document.open();
        document.write(pageContent);
        document.close();
        restoreFavicon();

        setTimeout(() => {
          modifyAllNavLinks();
          observeDomChanges();
        }, 500);
      }, 100);
    }
  };
  
  const executeScripts = (doc) => {
    doc.querySelectorAll("script").forEach((oldScript) => {
      if (!oldScript.textContent.trim() && !oldScript.src) return; // Ignore empty scripts
 
      const newScript = document.createElement("script");
      newScript.type = oldScript.type || "text/javascript";
     
      // Handle external scripts
      if (oldScript.src) {
        newScript.src = oldScript.src;
        newScript.async = oldScript.async;
      }
      // Handle inline scripts (including CDATA)
      else {
        let scriptContent = oldScript.textContent;
        scriptContent = scriptContent.replace("/* <![CDATA[ */", "").replace("/* ]]> */", ""); // Remove CDATA if present
        newScript.textContent = scriptContent;
      }
 
      document.body.appendChild(newScript);
    });
  };

  const modifyNavMenuLinks = (htmlText) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlText, "text/html");

    doc.querySelectorAll('a[href^="https://ifuturz.com/wp/"]').forEach((link) => {
      const slug = new URL(link.href).pathname.replace("/wp/", "");
      link.setAttribute("href", `/${slug}`);
    });

    return doc.documentElement.outerHTML;
  };

  const modifyAllNavLinks = () => {
    document.querySelectorAll('a[href^="https://ifuturz.com/wp/"]').forEach((link) => {
      const slug = new URL(link.href).pathname.replace("/wp/", "");
      link.setAttribute("href", `/${slug}`);

      link.addEventListener("click", (event) => {
        event.preventDefault();
        setLoading(true);
        navigate(`/${slug}`);
      });
    });
  };

  const observeDomChanges = () => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === 1) modifyAllNavLinks();
        });
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    observerRef.current = observer;
  };

  const restoreFavicon = () => {
    const link = document.createElement("link");
    link.rel = "icon";
    link.type = "image/png";
    link.href = "/favicon.ico";

    document.querySelector("link[rel='icon']")?.remove();
    document.head.appendChild(link);
  };

  // Show loader until content is fully ready
  if (loading) {
    return (
      <div className="loader-container">
        <ClipLoader size={60} color="#3498db" />
      </div>
    );
  }

  // If content is loaded, update the document
  if (pageContent) {
    updateDocumentContent();
  }

  return null;
};

export default Home;
