import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Homepage';
import './index.css'; // Import global styles

const App = () => {
  return (
    <div>
      
      <Router basename='/'>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          {/* <Route path="/:slug" element={<Home />} /> */}
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
